"use client"

import { pushToDataLayer } from "@/helpers/gtag";
import styles from "./NewsCard.module.scss";
import cn from "clsx";
import Image from "next/image";
import { getGlobalStateValue } from "@/context/utils";

export default function NewsCard({
  Heading,
  url_key,
  NewsProvider,
  FeaturedImage,
  formattedDate,
  isNewsList = false,
  title
}: any) {
  const ImageAttr = FeaturedImage?.source?.data?.[0]?.attributes;
  const provider = NewsProvider[0]?.text;


  const GAEventsHandler = () => {
    pushToDataLayer({
      event: "card_interaction",
      cta_text: Heading,
      section_name: title || "NA",
      page_type: getGlobalStateValue('pageType'),
    });
  };

  return (
    <a
      href={url_key || ""}
      onClick={GAEventsHandler}
      target="_blank"
      className={`block border-[0.0625rem] border-blu rounded-2xl lg:rounded-[0.833rem] ${styles.root}`}
    >
      <div
        className={cn(`flex flex-col justify-end`, styles.content, {
          [styles.contentNewsList]: isNewsList,
        })}
      >
        <Image
          src={ImageAttr?.url}
          alt={ImageAttr?.alternativeText || ""}
          className="object-cover"
          fill
          loading="lazy"
        />
      </div>
      <div className={`font-raleway ${styles.bottom}`}>
        <div className={`font-bold ${styles.heading}`}>
          {provider} . {formattedDate}
        </div>
        <div className={`font-semibold	 ${styles.text}`}>{Heading}</div>
        <div className={`homeSprite ${styles.blogArrow}`}></div>
      </div>
    </a>
  );
}
