"use client";

import styles from "./BlogList.module.scss";
import Carousel from "@molecules/Carousels/Carousel";
import BlogCard from "@molecules/BlogCard";
import ClickToScroll from "../NewLists/ClickToScroll";
import useWindowSize from "@/helpers/useWindowSize";

export default function BlogList({ data, title }:{ data: any, title: string }) {
  const { isDesktop } = useWindowSize();

  if (isDesktop) {
    return (
      <div className={`${styles.root}`} id="bloglist">
        <ClickToScroll id="bloglist" />
        {data?.map((d) => (
          <BlogCard key={d.id} id={d.id} {...d.attributes} title ={title} />
        ))}
      </div>
    );
  }
  return (
    <div className={`px-3 ${styles.root}`}>
      <Carousel
        slides={data?.map((d) => (
          <BlogCard key={d.id} id={d.id} {...d.attributes} title ={title}/>
        ))}
        config={{
          pagination: true,
          wrapperClass: styles.swiper,
          autoplay: {
            delay: 6000,
            disableOnInteraction: false,
          },
          spaceBetween: 20,
          slidesPerView: 1,
        }}
      />
    </div>
  );
}
