"use client";

import { getGlobalStateValue } from "@/context/utils";
import { pushToDataLayer } from "@/helpers/gtag";

const ClientWrapper = (props) => {

    const onClickHandler = () => {
      pushToDataLayer({
        event: "banner_course_interaction",
        cta_text: props.Program,
        section_name: "Hero Banner",
        header_title: props.Titles?.replace(/<\/?[^>]+(>|$)/g, ""),
        page_type: getGlobalStateValue("pageType"),
      });
    };

  return (
    <div className="banner-course-item">
      <a onClick={onClickHandler} href={props.href}>
        <h4>{props.Program}</h4>
        <p>{props.ProgramName}</p>
      </a>
    </div>
  );
};

export default ClientWrapper;
