"use client";
import styles from "./NewLists.module.scss";
import NewsCard from "@molecules/NewsCard";
import Carousel from "@molecules/Carousels/Carousel";
import ClickToScroll from "./ClickToScroll";
import useWindowSize from "@/helpers/useWindowSize";

export default function NewLists({ data, title }: any) {
  const { isDesktop } = useWindowSize();

  if (isDesktop) {
    return (
      <div className={`${styles.root}`} id="newslist">
        <ClickToScroll id="newslist" />
        {data?.map((d) => <NewsCard title={title} key={d.id} id={d.id} {...d.attributes} />)}
      </div>
    );
  }
  return (
    <div className={`px-3 ${styles.root}`}>
      <Carousel
        slides={data?.map((d) => (
          <NewsCard key={d.id} id={d.id} {...d.attributes} />
        ))}
        config={{
          pagination: true,
          wrapperClass: styles.swiper,
          autoplay: {
            delay: 6000,
            disableOnInteraction: false,
          },
          spaceBetween: 20,
          slidesPerView: 1,
        }}
      ></Carousel>
    </div>
  );
}
